import request from '@/utils/request'
import {fileDownloadApi} from '@/config/settings'
import qs from 'qs'
import Vue from "vue";

/**
 * 系统文件
 * @author GavinShaw
 * @date 2021-12-29
 */

/**
 * 文件下载
 * @param fileId
 * @param openType link(模拟a标签) | window(下载地址作为新窗口打开) 默认link
 *
 */
export function download(fileId, openType){
  if(!fileId){
    Vue.prototype.$baseMessage('缺失文件ID', 'error', 'vab-hey-message-error')
    return false;
  }
  openType = openType ? openType : 'link'
  const url = fileDownloadApi+fileId
  simulationClick(url, openType)
}


export function upload(params) {
  let data = new FormData();
  data.append("file", params.file);
  data.append("modules", params.modules);
  data.append("biz", params.biz);

  return request({
    url: 'api/storage',
    method: 'post',
    data
  })
}

/**
 * 逻辑删除文件
 * @param fileId
 */
export function remove (fileId){
  return request({
    url: 'api/storage/' + fileId,
    method: 'delete'
  })
}

/**
 * 批量逻辑删除
 */
export function removeMultiple(ids){
  return request({
    url: 'api/storage/multiple',
    method: 'delete',
    data: ids
  })
}


/**
 * 获取文件信息
 * @param ids
 */
export function fileInfo(ids){
  return request({
    url: 'api/storage/info/',
    method: 'post',
    data: ids
  })
}

/**
 * @param url
 * @param params
 */
export function downloadBlob(url, params) {
  return request({
    url: url + '?' + qs.stringify(params, { indices: false }),
    method: 'get',
    responseType: 'blob'
  })
}



/**
 * 模拟打开
 * @param url       链接地址
 * @param openType  打开方式 link: 模拟a便签 window 新窗口打开
 */
export function simulationClick(url, openType){
  // url = url + "?Authorization=" + getToken()
  if(!openType) openType = "link"
  const id = new Date().getTime();
  switch (openType){
    case 'link':
      const _a = document.createElement('a');
      _a.href = url
      _a.target = '_self'
      _a.id='downloadLink#'+id
      document.body.appendChild(_a);
      const a = document.getElementById('downloadLink#'+id)
      a.click()
      a.parentNode.removeChild(_a);
      break;
    case 'window':
      window.open(url)
      break;
    default:
      Vue.prototype.$baseMessage('未知打开方式', 'error', 'vab-hey-message-error')
      break;
  }
}

/**
 * 模拟打开
 * @param obj   blob 对象
 * @param name  文件名
 */
export function simulationClickBlob(obj, name) {
  const url = window.URL.createObjectURL(new Blob([obj]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
